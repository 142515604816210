import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`2021 State Of Dances`}</h1>
    <div style={{
      "position": "relative",
      "paddingBottom": "56.25%",
      "height": "0"
    }}><iframe src="https://www.loom.com/embed/852f3efb3f7842ed9d78507cd1d98d5a" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></div>
    <p>{`As Junior Class President, one of my main jobs is to oversee the planning and execution of the Irvine High School Prom. My council is currently working meticulously to see if we can run a safe dance-style event towards the end of the year. There have been many questions asked on the virtual table: Will it be for seniors only? How will we enforce social distancing and mask-wearing? Will it be as fun as it usually is? Realistically, all of those questions are up in the air and tough to answer at the moment.`}</p>
    <p>{`In January 2021, the Irvine High Junior Class Council prepared a 10-page proposal outlining the location, time, objective, rundown, menu, activities, digitalization, music, timelines, senior recognition, atmosphere, and most importantly, the safety of a modified Prom. We met multiple times to discuss what ideas we want to incorporate and how different it would look from what Prom is generally like. We were especially saddened that we weren't able to have a Homecoming or Formal, so we wanted to make Prom better than it's ever been before.`}</p>
    <p>{`The goal for the end of the 2020-21 year is to have some variation of an end-of-year event. Whether it be our modified Prom proposal, a senior breakfast, or literally anything— we're trying our best to work under the school, county, and state guidelines, as safety will always take importance. Our Prom proposal was a fun picnic-style event which would appeal to all types of people (not just the typical Prom-goers, but also people who might like video games, etc). . Even if we can't put an in-person event together, a virtual event is totally possible too! We can work on ways to make virtual events more engaging and fun!`}</p>
    <p>{`Nevertheless, next year seems to have some glimmer of hope. We don't know what it'll look like right now as things are being decided as you're reading this. Regardless if my council's 10-page Prom proposal happens, next year's Homecoming will blow your mind. Hopefully, it'll happen... picture this: you book a space on the practice field lawn with your friends online. On the day of the event, you come and there are blankets set-up for you and your friends to sit on and there is live music playing. Now, you and your friends decide to take photos at the photo booth. You pick out the photo style you want, the machine clicks and the images are automatically transferred to your phones. You can go play games with your friends at the many stations set-up and dance the night away. This is different than any Homecoming, Formal, or Prom you may have ever been to, but just imagine it. This is just an idea of what it could look like, happening safely, without sacrificing the fun.`}</p>
    <p>{`If we can do more in-person activities next year, I promise you that every event will be bigger and better than you've ever seen before. We're going to exceed expectations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      